.location-info {
    white-space: pre-line;
}

.map {
    background-color: #2b82d8;
    padding: 2px;
    z-index: 3;
    position: relative;
    margin-top: 20px;
    margin-bottom: 10px;
    display: flex;
    overflow: hidden;
}

.button-link {
    text-decoration: none;
    justify-content: center;
    display: flex;
}

.blue-button {
    background-color: #2b82d8;
    padding: 10px;
    border-radius: 3px;
    color: white;
    border-width: 0;
    display: flex;
    gap: 5px;
    font-weight: 600;
    font-size: 15px;
    cursor: pointer;

    span {
        transform: rotate(-45deg);
        display: block;
    }
}
