section {
    padding: 20px;
    box-sizing: border-box;
    white-space: pre-line;
    max-width: 940px;
    min-width: 100%;
    overflow-y: auto;
    h2 {
        font-weight: 700;
        letter-spacing: 0.03rem;
        font-size: 40px;
        line-height: 1.8rem;
        margin: 0px;
    }
    p {
        color: #2b82d8;

        font-weight: 500;
        letter-spacing: 0.03rem;
        line-height: 1.2rem;
    }
}

svg {
    fill: #2b82d8;
    stroke: #2b82d8;
}

.pageTitle {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 0.5rem;
    margin-block: 40px;

    svg {
        height: 3.5rem;
    }
}

.photoShelf {
    display: flex;
    justify-content: center;
}

.photoShelf > svg {
    padding: 0.5rem;
}

.timeline {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 2rem;
}

.timelineRow {
    display: flex;
    gap: 1rem;
    width: 100%;
    justify-content: flex-start;
}

.timelineText {
    display: flex;
    flex-direction: column;
    align-self: center;
}

.curvedLine {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
}
