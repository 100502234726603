.button-list {
    display: flex;
    gap: 10px;
    padding: 10px;
    flex-wrap: wrap;
    justify-content: center;

    button {
        white-space: nowrap;
    }
}

.rsvp-section {
    justify-content: center;
    text-align: center;
    display: flex;
    flex-direction: column;
}