.faq-section {
  width: 100%;
}

.faq-list {
  border: 1px solid #e9e9e9;
  border-radius: 5px;
  margin-bottom: 10px;
}

.faq-item {
  border-bottom: 1px solid #ccc;
}

.question {
  background-color: #2b82d8;
  color: white;
  padding: 10px;
  cursor: pointer;
  font-weight: 600;
  letter-spacing: 0.03rem;
}

.answer {
  background-color: #e9e9e9;
  padding: 10px;
  display: none;
  font-weight: 500;
}

.answer.open {
  display: block;
}
