.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    justify-content: center;
    gap: 1rem;
    height: 100%;
}

.code-input {
    border-width: 5px;
    border-color: #2b82d8;
    border-radius: 8px;
    color: #2b82d8;
    padding: 8px;
    font-weight: 600;
    font-size: 18px;
    border-style: solid;
    text-align: center;
}
