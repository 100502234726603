.header-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    transition: height 0.5s ease; /* Smooth height transition */
}

#welcome {
    padding-top: 20;
    padding-bottom: 20;
}

.names {
    font-weight: 800;
    font-size: 4rem;
    line-height: 3rem;
    padding: 0px 20px;
}

.details {
    padding: 0px 40px;
    text-align: end;
    font-size: 1.6rem;
    font-weight: 500;
}

.detailsText {
    line-height: 1.5rem;
}

.dayCount {
    color: white;
    background-color: #2b82d8;
    display: inline-block;
    padding: 5px;
    border-radius: 5px;
    text-transform: capitalize;
    font-size: small;
    font-weight: 600;
    letter-spacing: 0.03rem;
    line-height: 1rem;
}

.cordially {
    font-size: 1rem;
    font-weight: 800;
    letter-spacing: 0.03rem;
    padding: 0px 20px;
}

.swipe-container {
    position: fixed;
    transition: opacity 0.5s ease-in-out;
    opacity: 0;
    pointer-events: none;
    z-index: 1000;
    display: flex;
    justify-content: flex-end;
    inset-inline-end: 10px;
}

.swipe-container.visible {
    opacity: 1;
    pointer-events: auto; /* Allow interaction when visible */
}

.swipe-container-prev {
    position: fixed;
    transition: opacity 0.5s ease-in-out;
    opacity: 0;
    pointer-events: none;
    z-index: 1000;
    display: flex;
    justify-content: flex-start;
    inset-inline-start: 0px;
}

.swipe-left {
    width: 2rem;
    height: 2rem;
    box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.3);
    background-color: #2b82d8;
    border-color: white;
    border-style: solid;
    border-width: 2px;
    color: white;
    border-radius: 50%;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    font-weight: bold;
    img {
        width: 1rem;
        height: 1rem;
    }
    cursor: pointer;
}

.swipe-left.english img {
    animation: fadeinoutright 1.5s infinite;
}

.swipe-left.hebrew img {
    animation: fadeinoutleft 1.5s infinite;
}

.swipe-right {
    width: 2rem;
    height: 2rem;
    box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.3);
    background-color: #2b82d8;
    border-color: white;
    border-style: solid;
    border-width: 2px;
    color: white;
    border-radius: 50%;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    font-weight: bold;
    img {
        width: 1rem;
        height: 1rem;
    }
    cursor: pointer;
    transform: rotate(-180deg);
}

.swipe-right.english img {
    animation: fadeinoutright 1.5s infinite;
}

.swipe-right.hebrew img {
    animation: fadeinoutleft 1.5s infinite;
}

@keyframes fadeinoutdown {
    0% {
        opacity: 0;
        transform: translateY(-5px);
    }
    50% {
        opacity: 1;
        transform: translateY(0px);
    }
    100% {
        opacity: 0;
        transform: translateY(5px);
    }
}

@keyframes fadeinoutright {
    0% {
        opacity: 0;
        transform: translateX(-5px) rotate(-90deg);
    }
    50% {
        opacity: 1;
        transform: translateX(0px) rotate(-90deg);
    }
    100% {
        opacity: 0;
        transform: translateX(5px) rotate(-90deg);
    }
}

@keyframes fadeinoutleft {
    0% {
        opacity: 0;
        transform: translateX(5px) rotate(90deg);
    }
    50% {
        opacity: 1;
        transform: translateX(0px) rotate(90deg);
    }
    100% {
        opacity: 0;
        transform: translateX(-5px) rotate(90deg);
    }
}
